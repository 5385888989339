<template>
  <div>
    <!-- <section class="py-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14521638.285109874!2d-92.82342782189147!3d27.30311420794963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c1766591562abf%3A0xf72e13d35bc74ed0!2sFlorida!5e0!3m2!1sen!2sus!4v1562426706108!5m2!1sen!2sus"
        width="100%"
        height="600"
        frameborder="0"
        style="border:0"
        allowfullscreen
      />
    </section> -->

    <section>
      <v-container>
        <v-row justify="center">
          <v-col
            v-for="([icon, info1], i) in details"
            :key="i"
            cols="12"
            md="3"
          >
            <div
              class="text-center"
            >
              <a
                href="mailto:booking@goryangomusic.com"
                style="text-decoration:none"
              >
                <v-icon
                  size="64"
                  class="mb-3"
                  v-text="icon"
                />
              </a>
              <a href="mailto:booking@goryangomusic.com">
                <div
                  class="mb-2 headline"
                  v-text="info1"
                />
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      details: [
        ['mdi-email', 'booking@goryango.com', 'booking@goryango.com']
      ]
    }),

    methods: {
      booking () {
        this.href = 'mailto:booking@goryangomusic.com'
      }
    }
  }
</script>
